.textContent {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  line-height: 1.35;
}

.textContent p {
  margin: 0;
}

.textContent p:not(:last-child) {
  margin-bottom: 1rem;
}
