:root {
  --backgroundimg: url(gradient_dp.png);
  --backgroundimggrad: url(siteBgGrad.gif);
  --bannerEdge: rgb(246, 179, 224);
  --backgroundcolor: #FFEBD1;
}

.Home {
  padding-top: 15px;
  padding-bottom: 20px;
  top: 0;
  left: 0;
  height: 995px;
  width: 100%;
  margin: 0 auto;
  background-image: var(--backgroundimggrad), var(--backgroundimg);
  background-position: top, bottom;
  background-repeat: repeat-x, repeat;
  position: absolute;
  min-height: 100%;
  box-sizing: border-box;
}

.Inscription {
  padding-top: 15px;
  padding-bottom: 20px;
  top: 0;
  left: 0;
  height: 995px;
  width: 100%;
  margin: 0 auto;
  background-image: var(--backgroundimggrad), var(--backgroundimg);
  background-position: top, bottom;
  background-repeat: repeat-x, repeat;
  position: absolute;
  min-height: 100%;
}

.bannerContour {
  /*outline: 2px solid var(--bannerEdge);*/
}

.container {
  height: 995px;
}

.Site {
  padding: 2px;
  width: 765px;
  height: 990px;
  margin: auto;
  vertical-align: middle;
  background-color: var(--backgroundcolor);
  background-repeat: repeat;
}

.SiteInscription {
  padding: 2px;
  width: 765px;
  height: 630px;
  margin: auto;
  vertical-align: middle;
  background-color: var(--backgroundcolor);
  background-repeat: repeat;
}

.headerLimit {
  height: 9px;
  background-color: #efac71;
  padding-right: 1px;
  padding-left: 1px;
}

.sideBar {
  float: left;
  padding: 8px;
  height: 875px;
  width: 66px;
}

.sideBarIns {
  float: left;
  padding: 8px;
  height: 400px;
  width: 66px;
}

.content {
  padding-top: 8px;
  padding-left: 152px;
  height: 875px;
  width: 600px;
}

.contentIns {
  padding-top: 8px;
  padding-left: 154px;
  height: 400px;
  width: 600px;
}

.textnormal {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  margin-top: 7px;
  margin-right: 0px;
  margin-left: 10px;
  margin-bottom: 7px;
}

.ou {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  padding-left: 2px;
  padding-right: 2px;
}

.textrose {
  width: 140px;
  padding-top: 20px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 9pt;
  text-align: center;
  border-collapse: collapse;
  color: #d28e51;
  margin-bottom: 10px;
}

.textroseWP {
  text-align: center;
  width: 140px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 9pt;
  border-collapse: collapse;
  color: #d28e51;
  margin-bottom: 2px;
}

:lang(fr) .width {
  width: 83px;
}

:lang(es) .width {
  width: 57px;
}

:lang(ch) .width {
  width: 90px;
}

:lang(en) .width {
  width: 69px;
}

.widthOldCred {
  margin-top: 3px;
  width: 160px;
}

.widthRankings {
  width: 120px;
  margin-right: 20px;
}

.credentials {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  margin: 7px 0px 7px 10px;
  text-align: left;
}

.menutitle {
  width: 140px;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
  color: #efac71;
  font-weight: bold;
  font-size: 11pt;
  line-height: 11pt;
  border-bottom: 3px solid #efac71;
  margin: 8px 5px 3px 0px;
}

.header1 {
  width: 120px;
  height: 23px;
  color: #ffebd1;
  background-color: #efac71;
  margin: 5px 5px 10px 0px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15pt;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  letter-spacing: 3px;
}

.presentation {
  width: 580px;
  color: #ffebd1;
  background-color: #efac71;
  margin: 5px 5px 10px 0px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 15pt;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  letter-spacing: 3px;
}

.header2 {
  margin-top: 20px;
  color: #efac71;
  margin-bottom: 3px;
  border-bottom: 1px solid #efac71;
  font-size: 14pt;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.padBot {
  padding-bottom: 8px;
}

.Button {
  background-color: #d28e51;
  color: #fcead1;
  cursor: pointer;
  margin-top: 10px;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.Button:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.ButtonRankings {
  width: 100px;
  background-color: #d28e51;
  color: #fcead1;
  cursor: pointer;
  padding-left: 18px;
  padding-right: 20px;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
}

.ButtonRankings:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.links {
  color: #c37253;
  font-weight: bold;
  text-decoration: underline;
  text-decoration: none;
  font-size: 10pt;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
  border: 0;
  padding: 0;
  background: none;
}

.links:hover {
  text-decoration: underline;
  color: #8a4524;
  cursor: pointer;
}

.useOne {
  border: 0px solid #ffebd1;
  background: transparent;
  color: #c37253;
  font-weight: bold;
  text-decoration: underline;
  text-decoration: none;
  font-size: 10pt;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}

.useOne:hover {
  text-decoration: underline;
  color: #8a4524;
  cursor: pointer;
}

.giveClan {
  border: 0px solid #ffebd1;
  background: transparent;
  margin-right: 1rem;
  color: #c37253;
  font-weight: bold;
  text-decoration: underline;
  text-decoration: none;
  font-size: 10pt;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}

.giveClan:hover {
  text-decoration: underline;
  color: #8a4524;
  cursor: pointer;
}

.linksunderlined {
  text-decoration: underline;
  color: #c37253;
  font-weight: bold;
  font-size: 10pt;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.linksunderlined:hover {
  color: #8a4524;
  cursor: pointer;
}

.flags {
  width: 140px;
  padding-top: 15px;
  text-align: center;
  font-size: 10pt;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}

.miniflags {
  border: 1px solid #c37253;
}

.miniflags:hover {
  border: 1px solid #000000;
  cursor: pointer;
}

.flagpadding {
  border: 2px solid #ffebd1;
  background: transparent;
  padding-right: 3px;
  padding-left: 3px;
}

.chapter {
  margin-top: 10px;
  border-collapse: collapse;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  vertical-align: top;
}

.chapterImg {
  border: 2px solid #f7d0a6;
  padding: 3px;
}

.chapterText {
  padding: 3px;
  margin-top: 5px;
  margin-left: 5px;
  border-collapse: collapse;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  vertical-align: top;
}

.chapterPantheon {
  width: 314px;
  margin-left: 35px;
  margin-right: 35px;
  border-collapse: collapse;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt;
  vertical-align: top;
}

.siteFooter {
  padding-top: 30px;
  background-position: left bottom;
  background-repeat: no-repeat;
  text-align: center;
  vertical-align: bottom;
  padding-bottom: 8px;
  padding-right: 8px;
  height: 60px;
  font-size: 8pt;
  text-align: center;
  color: #c37253;
  font-weight: bold;
  text-decoration: underline;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 8pt;
  border-collapse: collapse;
}

.footer1 {
  padding-left: 40px;
  transform: translate(80px, -20px);
}

.footer1Ins {
  padding-top: 50px;
  padding-left: 170px;
}

.footer2 {
  text-decoration: none;
  text-align: center;
  vertical-align: bottom;
  padding-right: 8px;
  font-size: 8pt;
  text-align: center;
  color: #c37253;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 8pt;
  border-collapse: collapse;
}

:lang(fr) .imageSpan {
  content: url("icon_help.gif");
}

:lang(en) .imageSpan {
  content: url("icon_help.en.gif");
}

:lang(es) .imageSpan {
  content: url("icon_help.sp.gif");
}

:lang(fr) .imageSpanF {
  content: url("icon_help.gif");
  margin-bottom: 3px;
  margin-left: 4px;
}

:lang(en) .imageSpanF {
  content: url("icon_help.en.gif");
  margin-bottom: 3px;
  margin-left: 4px;
}

:lang(es) .imageSpanF {
  content: url("icon_help.sp.gif");
  margin-bottom: 3px;
  margin-left: 4px;
}

.smalltooltip {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt !important;
  background-image: url("smallTooltip.jpg") !important;
  width: 222px !important;
  height: 56px !important;
  word-wrap: break-word !important;
  padding-left: 7px !important;
  padding-top: 3px !important;
  color: #ffebd1 !important;
  word-wrap: break-word !important;
  text-align: start;
}

.mediumtooltip {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 10pt !important;
  background-image: url("mediumTooltip.jpg") !important;
  width: 222px !important;
  height: 95px !important;
  padding-left: 7px !important;
  padding-top: 3px !important;
  color: #ffebd1 !important;
  word-wrap: break-word !important;
  text-align: start;
}

.largetooltip {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif !important;
  font-size: 10pt !important;
  background-image: url("largeTooltip.jpg") !important;
  width: 222px !important;
  height: 153px !important;
  padding-left: 7px !important;
  padding-top: 3px !important;
  color: #ffebd1 !important;
  word-wrap: break-word !important;
  text-align: start;
}

.collectiontooltip {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif !important;
  font-size: 8pt !important;
  background-image: url("largeTooltip.jpg") !important;
  width: 222px !important;
  height: 149px !important;
  padding-left: 7px !important;
  padding-top: 7px !important;
  color: #ffebd1 !important;
  word-wrap: break-word !important;
  text-align: start;
}

.collectiontooltipPlayer {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif !important;
  font-size: 8pt !important;
  background-image: url("largeTooltip.jpg") !important;
  width: 222px !important;
  height: 149px !important;
  padding-left: 7px !important;
  padding-top: 7px !important;
  color: #ffebd1 !important;
  word-wrap: break-word !important;
  text-align: start;
  line-height: 14px;
}

.collectiontooltipSmallFont {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif !important;
  font-size: 8pt !important;
  background-image: url("largeTooltip.jpg") !important;
  width: 222px !important;
  height: 149px !important;
  padding-left: 7px !important;
  padding-top: 7px !important;
  color: #ffebd1 !important;
  word-wrap: break-word !important;
  text-align: start;
}

.validerInscription {
  width: 420px;
  background-color: #d28e51;
  color: #fcead1;
  cursor: pointer;
  padding-left: 20px;
  padding-right: 20px;
  border-top: 2px solid #f7c2a4;
  border-left: 2px solid #f7c2a4;
  border-bottom: 2px solid #c37253;
  border-right: 2px solid #c37253;
  margin-top: 15px;
}

.validerInscription:hover {
  background-color: rgba(138, 69, 36, 0.9);
  cursor: pointer;
}

.fieldsInscription {
  padding-bottom: 15px;
  child-align: left;
}

.checkboxInscription {
  margin-left: 54px;
}

.acceptLine {
  margin-left: 50px;
}

.badLoginDialog {
  padding-bottom: 10px;
  background-color: #ffebd1;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-size: 14pt;
  color: #c37253;
}

.header4 {
  width: 592px;
  color: #efac71;
  margin-top: 10px;
  padding-left: 40px;
  margin-bottom: 10px;
  font-size: 12pt;
  font-weight: 700;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  font-style: italic;
  letter-spacing: 1px;
}

.helpText {
  margin-bottom: 15px;
  text-align: justify;
}

.etwinMiniBanner {
  margin-top: 4px;
  margin-left: 6px;
  width: 120px;
}

.canvasPage {
  position:fixed;
  padding:0;
  margin:0;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background-color: #FFEBD1;
}
