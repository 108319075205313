.row {
  --gap: 0.75rem;
  display: flex;
  gap: var(--gap);
}

.row.is-gap-large {
  --gap: 3rem;
}
.row.is-gap-medium {
  --gap: 1.5rem;
}

.row.is-gap-small {
  --gap: 0.25rem;
}

.row.is-center {
  justify-content: center;
}

.row.is-vcenter {
  align-items: center;
}

.col.is-grow {
  flex: 1;
}

.col.is-vcenter {
  align-self: center;
}
