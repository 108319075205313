.sectionLight {
  margin: 0 -0.5rem;
  padding: 0 0.5rem;
}

.sectionLight-title {
  margin: 0.5rem -0.5rem;
  color: #efac71;
  border-bottom: 1px solid #efac71;
  font-size: 14pt;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
}

.sectionLight-title:not(:first-child) {
  margin-top: 1rem;
}
