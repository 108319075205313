.asyncSection {
  position: relative;
}

.asyncSection-loader {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.asyncSection-loader:not(:first-child) {
  position: absolute;
  inset: 0;
  backdrop-filter: blur(1px);
}
