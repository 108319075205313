.cashAmount {
  align-items: center;
  font-weight: bold;
  font-style: normal;
  font-size: 11pt;
  color: black;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}

.cashAmount::after {
  content: url("../media/minis/tiny_coin.gif");
  margin-left: 4px;
}
