@import "./imports";

@import "./helpers/spacing";

body {
  font-size: 10pt;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  line-height: 1.35;
}

button {
  font: inherit;
}

.image {
  display: block;
  height: auto;
  max-width: 100%;
}

.has-text-centered {
  text-align: center !important;
}

.has-text-justified {
  text-align: justify !important;
}

.has-text-left {
  text-align: start !important;
}

.has-text-right {
  text-align: end !important;
}

// 'center' | 'space-around' | 'space-between' | 'space-evenly' | 'flex-start' | 'flex-end'
@each $value
  in (center, space-around, space-between, space-evenly, flex-start, flex-end)
{
  .is-justify-#{$value} {
    justify-content: $value !important;
  }
}
