.section {
  padding: 0 13px;
}

.section-title {
  color: #ffebd1;
  background-color: #efac71;
  margin: 5px -13px 10px;
  padding: 0 10px;
  font-size: 15pt;
  font-weight: bold;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  letter-spacing: 3px;
}

.section:not(:first-child) {
  margin-top: 1rem;
}

.sectionContent-noPadding {
  margin: 0 -13px;
}
