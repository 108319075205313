.sidebar {
  align-self: flex-start;
  width: 140px;
  padding: 8px;
  margin-right: auto;
}

.sidebarSection {
  padding-left: 6px;
}
.sidebarSection-title {
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
  color: #efac71;
  font-weight: bold;
  font-size: 11pt;
  line-height: 1;
  border-bottom: 3px solid #efac71;
  margin: 8px 5px 3px -6px;
}

.sidebarLinks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  padding: 1px 0;
}

.sidebarLink {
  all: unset;
  display: inline-flex;
  align-items: center;
  gap: 3px;
  color: #c37253;
  font-weight: bold;
  text-decoration: underline;
  text-decoration: none;
  font-size: 10pt;
  text-align: left;
  font-family: Trebuchet MS, Arial, Verdana, sans-serif;
  border-collapse: collapse;
}
.sidebarLink:hover,
.sidebarLink:focus-within {
  text-decoration: underline;
  color: #8a4524;
  cursor: pointer;
}

.sidebarLink--notify {
  color: RED;
}
.sidebarLink--notify::after {
  content: url("../media/minis/anim_notify.gif");
}
.sidebarLink--notify:hover,
.sidebarLink--notify:focus-within {
  color: RED;
}
