.homeChapter {
  margin-top: 0.75rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.homeChapter img {
  border: 2px solid #f7d0a6;
  padding: 3px;
}

.homeChapter:nth-child(odd) {
  flex-direction: row-reverse;
}
